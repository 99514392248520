<template>
    <div>
        <div class="wr">
            <div class="center">
                <img src="../assets/imgs/uploads/Group508.png" alt="" style="width: 336px;height: 220px;">
            </div>
            <div class="text">
                <div class="center">Thank you!</div>
                <div class="center">Your application has been Sent </div>
            </div>
            <div class="center">We will comfirm and contact you by email as soon as possible.</div>
            <router-link to="/" class="bk">Back to Home page</router-link>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wr {
    width: 633px;
    margin: 132px auto 250px;
}

.center {
    display: flex;
    justify-content: center;
}

.text {
    margin-top: 60px;
    font-family: 'Jomolhari';
    font-size: 36px;
}
.bk{
    margin: 40px auto 0;
    width: 296px;
    height: 47px;
    color: #FFF !important;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    background-color: #CA141D;
    border-radius: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>